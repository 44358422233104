//import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const NotFound404 = () => {
  return (
    <Layout
      title={"Page not found"}
      description={"The page you're looking for doesn't exist"}
    >
      <StyledHeading>Page Not Found</StyledHeading>
      PAGE NOT FOUND !
    </Layout>
  )
}

export default NotFound404

const StyledHeading = styled.h1`
  color: #000;
  z-index: -999999999;
  position: absolute;
`
